package io.zsoft.util

object Constants {
    const val SECTION_WIDTH = 1920
    const val FONT_FAMILY = "Roboto"
    const val SHORT_TEXT = "This is a shorter text that will be printed in the About Me section of this page"
    const val WEBSITE = "https://i65.zsoft.io"
}

object Res {
    object Image {
        const val background = "background.svg"
        const val about = "about_image.png"
        const val logo = "logo.svg"
        const val portfolio1 = "portfolio1.png"
        const val portfolio2 = "portfolio2.png"
        const val portfolio3 = "portfolio3.png"
        const val portfolio4 = "portfolio4.jpg"
        const val portfolio5 = "portfolio5.png"
        const val avatar1 = "avatar1.png"
        const val avatar2 = "avatar2.png"
        const val avatar3 = "avatar3.png"
        const val avatar4 = "avatar4.png"
        const val avatar5 = "avatar5.png"
        const val avatar6 = "avatar6.png"
    }
    object Icon {
        const val web = "web_icon.svg"
        const val seo = "seo_icon.svg"
        const val design = "design_icon.svg"
        const val business = "business_icon.svg"
        const val apple = "apple_icon.svg"
        const val android = "android_icon.svg"
        const val link = "link_icon.svg"
        const val user = "user_icon.svg"
        const val shield = "shield_icon.svg"
        const val happy = "happy_icon.svg"
        const val checkmark = "checkmark_icon.svg"
        const val star = "star_icon.svg"
    }
}